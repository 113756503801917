<template lang="pug">
LayoutWrapper.charter-default
  template(#header)
    Header
    CharterMobileMenu
  template(#solresorContent)
    slot
  template(#footer)
    LazyFooter
</template>

<script setup>
const { t } = useI18n()

const { getLocaleMessages: localeMessages } = useLocaleStore()

useHead(() => ({
  titleTemplate: `%s - ${t('siteTitleBrand')}`,
  // link: [
  //   { rel: 'icon', type: 'image/x-icon', href: t('favicon') }
  // ],

  script: [{
    innerHTML: () => localeMessages.organizationData,
    type: 'application/ld+json',
  }],

  meta: [
    { hid: 'author', name: 'author', content: () => t('siteTitleBrand') },
    {
      hid: 'og:title',
      property: 'og:title',
      content: () => `${t('siteTitleBrand')}`,
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: () => `${t('siteTitleBrand')}`,
    },
  ],
}))
</script>

<style lang="scss">
.identity--solresor {
  .charter-default {
    .layout-container,
    .banner-layout.container-content {
      max-width: $container-width-without-sidebar;
    }
  }
}
</style>
