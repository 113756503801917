
// Migrated
<template lang="pug">
.d-inline-block.overflow-hidden
  DropdownToggle(
    v-bind="dropdownToggleProps"
    height="auto"
    :class="dropdownClass"
  )
    slot(name="trigger" v-bind="{ open, close, toggle, isOpen }")

  .position-relative
    .relative-dropdown-menu(
      :style="{ height: isOpen ? `${menuHeight}px` : 0 }"
      :class="menuClass"
    )
      slot(name="content" v-bind="{ open, close, toggle, isOpen }")

    .relative-dropdown-menu.invisible.position-absolute.pe-none(
      ref="dropdownMenu"
      :class="menuClass"
    )
      slot(name="content" v-bind="{ open, close, toggle, isOpen }")
</template>

<script>
const STATUS = {
  open: 'opened',
  closed: 'closed',
}

export default defineNuxtComponent({
  props: {
    toggleClass: {
      type: [String, Object, Array],
      default: '',
    },

    initialExpanded: {
      type: Boolean,
      default: false,
    },

    dropdownClass: {
      type: [String, Object, Array],
      default: null,
    },

    menuClass: {
      type: String,
      default: null,
    },
  },

  emits: ['open', 'close', 'hide', 'show'],

  data: vm => ({
    id: `dropdown-${vm.$.uid}`,
    status: STATUS.closed,
    menuHeight: 0,
  }),

  computed: {
    dropdownToggleProps () {
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      const { toggleClass, initialExpanded, menuClass, ...dropdownToggleProps } = this.$props

      return dropdownToggleProps
    },

    isOpen () {
      return this.status === STATUS.open
    },
  },

  async mounted () {
    this.$nextTick(() => {
      if (this.initialExpanded) {
        this.status = STATUS.open
      }
    })

    if (!this.$refs.dropdownMenu) {
      await this.$nextTick()
    }

    this.updateMenuHeight()
    this.menuResizeObserver = new ResizeObserver(this.updateMenuHeight)
    this.menuResizeObserver.observe(this.$refs.dropdownMenu)
  },

  beforeUnmount () {
    this.menuResizeObserver?.disconnect?.()
  },

  methods: {
    updateMenuHeight () {
      this.menuHeight = this.$refs.dropdownMenu?.offsetHeight || 0
    },

    open () {
      this.status = STATUS.open
      this.$emit('open')
    },

    close () {
      if (this.status === STATUS.open) {
        this.status = STATUS.closed
        this.$emit('close')
      }
    },

    toggle () {
      if (this.isOpened()) {
        this.close()
      } else {
        this.open()
      }
    },

    hide () {
      this.close()
      this.$emit('hide')
    },

    show () {
      this.open()
      this.$emit('show')
    },

    isOpened () {
      return this.isOpen
    },

    isClosed () {
      return !this.isOpen
    },
  },
})
</script>

<style lang="scss">
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.relative-dropdown-menu {
  transition: height 350ms;
  overflow: hidden;
}
</style>
