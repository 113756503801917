import dayjs from 'dayjs'

import searchModes from '@layers/web/constants/search-modes'

export const DEFAULT_CUSTOM_DURATION = {
  type: 'custom',
  value: 'custom',
  custom: [2, 30],
  maxValue: 365,
}

export const useDealfinderStore = defineStore('dealfinder', {
  state: () => ({
    tripLengthsByAirport: [],

    selectedAirport: null,
    selectedDuration: {},
    selectedCharterTags: {},

    destination: null,
    stopOverList: {},

    allDurations: [
      DEFAULT_CUSTOM_DURATION,
    ],

    dealDates: [],

    startDate: null,
    // Temporary, until we have a hotel state
    hotel: null,
    refetchedMoreStopovers: false,

    durationsWithOverrides: [],
    dealfinderCurrentSlideDate: 0,
  }),
  getters: {
    getTripLengthByAirport: state => (airportCode) => {
      const item = state.tripLengthsByAirport
        .find(({ airport }) => airport === airportCode)

      return item ? item.triplengths : []
    },

    getCurrentTripLength (state) {
      if (!state.selectedAirport?.airport) {
        return []
      }
      return this.getTripLengthByAirport(state.selectedAirport?.airport)
        .map(t => ({
          ...t,
          value: { value: t.name },
        }))
    },

    currentDurationDates: (state) => {
      const duration = state.selectedDuration
      const option = this.getCurrentTripLength
        .find(d => dealfinderUtils.findDatesByDuration(d, duration))
      return (option?.dates || []).filter(d => !!d?.price)
    },

    airportList: (state) => {
      if (!state.tripLengthsByAirport) {
        return []
      }

      const airportStopovers = state.stopOverList || {}
      return state.tripLengthsByAirport
        .filter(c => !!c.triplengths?.length && airportStopovers?.[c.airport])
        .map(c => ({
          ...c,
          featured_airport: c?.airport,
          stopovers: airportStopovers?.[c.airport],
        }))
        .filter(ap => !!ap)
    },

    cheapestDates: state => (state.dealDates || [])
      .map(dealfinderUtils.transformCheapestDate)
      .reduce(dealfinderUtils.reduceCheapestDate, {}),

    cheapestDateList (state) {
      return [...Object.values(state.cheapestDates || {})].sort((a, b) => a.ts - b.ts)
    },

    currentActiveIndex (state) {
      return function (selectedIdx = null) {
        const idx = selectedIdx || state.startDate
        if (idx) {
          const date = dayjs(idx)

          const y = date.format('YYYY')
          const m = date.format('MMM')

          const matchingMonthIndex = state.cheapestDateList.findIndex(month => month.m === m && month.y === y)
          state.dealfinderCurrentIndex = matchingMonthIndex === -1 ? 0 : matchingMonthIndex
          return matchingMonthIndex === -1 ? 0 : matchingMonthIndex
        }

        return 0
      }
    },

    durationSafe: state => ({
      ...DEFAULT_CUSTOM_DURATION,
      ...state.selectedDuration,
    }),

    stopovers: state => state?.selectedAirport?.stopovers || [],
  },
  actions: {
    SET_DEALFINDER_CURRENT_SLIDE_DATE (data) {
      this.dealfinderCurrentSlideDate = data
    },
    //old mutations
    SET_DESTINATION (data) {
      this.destination = data
    },

    SELECT_DURATION (data) {
      this.selectedDuration = data
    },

    SELECT_AIRPORT (airport) {
      if ((airport?.stopovers || []).length === 0) {
        airport.stopovers = this.stopOverList?.[airport?.airport] || this.destination?.stopOvers?.[airport?.airport] || []
      }
      this.selectedAirport = airport
    },

    INIT_TRIP_LENGTHS (data) {
      this.tripLengthsByAirport = data
    },

    SET_DURATION_OPTIONS (data) {
      this.allDurations = data
    },

    SET_STOPOVER_LIST (data) {
      this.stopOverList = data
    },

    SET_DEAL_DATES (data) {
      this.dealDates = [...data]
    },

    SET_START_DATE (data) {
      this.startDate = data
    },

    SET_HOTEL (data) {
      this.hotel = data
    },

    SET_REFETCHED_STATUS (data) {
      this.refetchedMoreStopovers = data
    },

    SET_DURATIONS_WITH_OVERRIDES (data) {
      this.durationsWithOverrides = data
    },

    SET_CHARTER_TAGS (data) {
      this.selectedCharterTags = data
    },
    //old actions
    init () {
      const charterPackagesStore = useCharterPackagesStore()

      this.SET_DURATION_OPTIONS([
        ...(charterPackagesStore?.durations || []),
        DEFAULT_CUSTOM_DURATION,
      ])

      this.initDestination()
      this.repopulate()
      this.initParameters()
      this.initDurations()

      return true
    },

    initDestination () {
      const { currentRoute } = useRouter()
      const route = currentRoute.value.name
      const charterStore = useCharterStore()
      const searchWidgetsStore = useSearchWidgetsStore()

      const charterDestination = charterStore.destination
      const stagedDestination = searchWidgetsStore?.destinations?.stagedDestinations?.[0]

      const destination = route?.name === 'hotel-id'
        ? stagedDestination || charterDestination
        : charterDestination || stagedDestination

      this.SET_DESTINATION(destination || {})
      if (destination?.stopOvers) {
        this.SET_STOPOVER_LIST(destination?.stopOvers)
      }
    },

    initParameters () {
      const { currentRoute } = useRouter()
      const rootStore = useRootStore()
      const charterStore = useCharterStore()
      const charterPackagesStore = useCharterPackagesStore()

      const query = currentRoute.value?.query
      const airportList = rootStore.charterAirports || []
      // -- Initial start date
      this.SET_START_DATE(query?.date || query?.month || charterStore.selectedStartDate?.dateRange?.start)

      // -- Initial room values
      try {
        const parsedRoomsFromQuery = parseRoomsFromURL(query)
        if (parsedRoomsFromQuery !== null) {
          charterPackagesStore.SET_ROOMS(parsedRoomsFromQuery)
        }
      } catch {
        // do nothing
      }

      const { defaultAirport } = useLocale()

      // -- Initial airport
      let airport = airportList
        ?.find(airport => airport.airport === (rootStore.selectedAirportIata ?? defaultAirport.airport))

      if (query?.airport) {
        airport = airportList.find(ap => ap?.airport === query.airport)
      }
      if (!airport?.airport && charterPackagesStore?.selectedAirport?.airport) {
        airport = deepClone(charterPackagesStore.selectedAirport)
      }

      if (!airport?.airport) {
        airport = airportList?.[0]
      }

      const airportStopovers = this.stopOverList?.[airport?.airport] || []
      if (airportStopovers.length === 0) {
        const airportWithStopovers = this.stopOverList[defaultAirport.airport]
          ? airportList.find(ap => ap.airport === defaultAirport.airport)
          : airportList.find(ap => this.stopOverList?.[ap?.airport])

        if (airportWithStopovers) {
          airport = deepClone(airportWithStopovers)
        }
      }
      if (airport?.airport) {
        this.SELECT_AIRPORT({
          ...airport,
          stopovers: airportStopovers,
        })
      }

      // - Initial stopovers
      let stops = null
      if (query?.stopover || query?.stopovers) {
        stops = parseInt(query.stopover || query?.stopovers)
      } else if (airportStopovers > 0) {
        stops = airportStopovers?.[0]
      } else if (this.stopovers.length > 0) {
        stops = this.stopovers?.[0]
      }
      if (stops !== null) {
        charterPackagesStore.SET_FILTER_STOPOVER(stops)
      }

      // Charter tags
      if (query?.charterTags) {
        this.SET_CHARTER_TAGS((query?.charterTags || '').split(',') || [])
      }
    },

    initDurations () {
      const { currentRoute } = useRouter()

      const query = currentRoute.value?.query
      this.setDurationsWithOverrides()

      let duration = null
      let initialDuration = null
      let localDuration

      const queryD = dealfinderUtils.parseDayLengthFromQuery(query)
      const fallback = this.getCurrentTripLength?.[0] || null
      try {
        localDuration = window.localStorage.getItem('charterDuration')
      } catch (e) {
        //do nothing
      }

      if (queryD >= 0) {
        initialDuration = this.getCurrentTripLength.find(d => !!d?.dates.find(dd => dd?.days === queryD))
      } else if (localDuration && this.getCurrentTripLength?.find(d => d?.name === localDuration)) {
        initialDuration = this.getCurrentTripLength.find(d => d?.name === localDuration)
      } else {
        initialDuration = fallback
      }

      if (this.durationsWithOverrides.length > 0) {
        const searchValue = initialDuration?.value?.value || queryD || initialDuration?.value
        const durationFind = this.durationsWithOverrides.find(d => d.value === searchValue) || this.durationsWithOverrides?.[0]
        if (durationFind) {
          duration = {
            ...initialDuration,
            custom: [],
            value: durationFind,
            type: 'value',
          }
        }
      }
      if (!duration && queryD) {
        const res = this.getCurrentTripLength.filter(dd => dd?.value === queryD)
        if (res.length > 0) {
          duration = res[0]
        } else {
          duration = {
            value: 'custom',
            type: 'custom',
            custom: [queryD, queryD],
          }
        }
      }
      if (!duration) {
        duration = initialDuration || fallback
      }

      if (!duration?.type) {
        if (duration?.value || duration?.value?.value || !isNaN(duration?.name)) {
          duration.type = 'value'
        } else {
          duration.type = 'custom'
        }
      }

      this.SELECT_DURATION(duration)
    },

    repopulate () {
      const charterPackagesStore = useCharterPackagesStore()
      const rootStore = useRootStore()

      const packages = charterPackagesStore.packages

      const tripLengthsByAirport = (rootStore.charterAirports || [])
        .map(airport => ({
          airport: airport?.airport,
          triplengths: dealfinderUtils.parseTripLengthsByPackagesAndDurations(packages, this.allDurations),
        }))

      this.INIT_TRIP_LENGTHS(tripLengthsByAirport)

      const duration = this.selectedDuration
      const airport = this.selectedAirport?.airport
      if (airport && duration) {
        const tripLengthsForAirport = tripLengthsByAirport.find(({ airport }) => airport === this.selectedAirport?.airport)?.triplengths || []

        const selectedDurationOption = tripLengthsForAirport
          .map(t => ({ ...t, value: { value: t.name } }))
          .find(d => dealfinderUtils.findDatesByDuration(d, duration))

        const calcRegularPricePerPerson = d => packages
          .find(pkg => pkg.tripid === d.tripid)?.regular_pricepp || null

        const dates = (selectedDurationOption?.dates || [])
          .filter(d => !!d?.price)
          .map(d => ({ ...d, regularPricePerPerson: calcRegularPricePerPerson(d) }))

        this.SET_DEAL_DATES(dates)
      } else {
        this.SET_DEAL_DATES([])
      }

      return true
    },

    async search () {
      const charterPackagesStore = useCharterPackagesStore()
      const charterStore = useCharterStore()

      const { rooms, filterStopover: stopovers } = charterPackagesStore
      const { selectedAirport, hotel } = this

      const duration = this.durationSafe
      if ((!duration?.value && duration?.type === 'value') || !selectedAirport) {
        return
      }
      const { type, value, custom } = duration
      const [min, max] = custom

      this.SET_REFETCHED_STATUS(false)

      const tripLength = type === 'custom'
        ? { min: +min, max: +max }
        : { min: value?.min || value?.value, max: value?.max || value?.maxValue }
      const tripLengthByWeekday = type === 'custom'
        ? null
        : (value?.lengthsByWeekday || null)

      const params = {
        airport: selectedAirport.airport,
        rooms,
        tripLength,
        tripLengthByWeekday,
        mode: 'dateCalendar', // dateCalendar | raw
        stopOver: { max: stopovers },
      }
      if (hotel && hotel?.property_id) {
        params.hotel_code = hotel?.property_id
        params.supplier = hotel?.supplier
      } else {
        params.destinations = [charterStore?.destination?.destination_id].filter(Boolean)
      }
      if (this.selectedCharterTags.length > 0) {
        params.charterTags = this.selectedCharterTags
      }

      const result = await charterPackagesStore.searchDealfinder(params)

      if ((result || []).length === 0) {
        return this.retryFetchWithMoreStopovers(params)
      } else {
        return result
      }
    },

    async retryFetchWithMoreStopovers (params) {
      const charterPackagesStore = useCharterPackagesStore()

      const { filterStopover: selectedStopovers } = charterPackagesStore

      const maxStopovers = Math.max(...this.stopovers)

      if (maxStopovers > selectedStopovers) {
        this.SET_REFETCHED_STATUS(true)
        charterPackagesStore.SET_FILTER_STOPOVER(maxStopovers)

        await timeout(1000)
        await charterPackagesStore.searchDealfinder({
          ...params,
          stopOver: { max: maxStopovers },
        })

        return true
      }
      return false
    },

    setDurationsWithOverrides () {
      const charterPackagesStore = useCharterPackagesStore()

      const { selectedAirport: ap } = this
      if (this.destination) {
        if (this.destination?.dayGroupsByOrigin) {
          const { dayGroupsByOrigin: dgo } = this.destination

          if (dgo?.[ap?.airport]) {
            this.SET_DURATIONS_WITH_OVERRIDES(dgo[ap.airport].map(dealfinderUtils.transformDayGroupToDurationValue))
            return
          }
        }
        if ((this.destination?.daygroups || []).length > 0) {
          this.SET_DURATIONS_WITH_OVERRIDES(this.destination.daygroups.map(dealfinderUtils.transformDayGroupToDurationValue))
          return
        }
      }

      this.SET_DURATIONS_WITH_OVERRIDES(charterPackagesStore?.durations)
    },

    prepareSearch (day) {
      const charterPackagesStore = useCharterPackagesStore()
      const charterStore = useCharterStore()

      const charterDestination = charterStore?.destination
      const { rooms, filterStopover } = charterPackagesStore
      const { selectedDuration, selectedAirport } = this
      try {
        window.localStorage.setItem('charterDuration', selectedDuration?.name)
      } catch (e) {
        //do nothing
      }

      const params = {
        selectedAirport,
        selectedDestinations: [{
          destination_id: charterDestination.destination_id,
          name: charterDestination.title,
        }],
        selectedDestinationsL1: [],
        rooms,
        selectedStartDate: {
          selectedDate: dayjs(day.date),
        },
        selectedStartDateRange: 0,
        selectedDuration,
        tags: [],
        board: 1,
        rating: [5, 4, 3, 2, 1],
        stopover: filterStopover,
      }

      charterPackagesStore.SET_FILTER_TAGS(params.tags)
      charterPackagesStore.SET_FILTER_BOARD(params.board)
      charterPackagesStore.SET_FILTER_RATING(params.rating)

      const options = charterPackagesStore.getURLParameters({ ...params, searchMode: searchModes.calendar })

      charterPackagesStore.RESET_SEARCHOPTIONS(null)

      return options
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDealfinderStore, import.meta.hot))
}