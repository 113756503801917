// Migrated
<template lang="pug">
ScreenWidthProvider.row.mx-0(v-slot="{ isNarrower }")
  .col-12.col-lg-8.position-relative.min-h-px-400.mx-0.mb-3.mb-lg-0.px-0
    .position-absolute.w-100.h-100.d-flex.flex-column.pr-lg-3.pr-lg-5.pt-lg-3.pb-lg-1
      .row.no-gutters.h-50
        .col-6.pr-px-10.h-100
          ResponsiveImage.w-100.h-100(:image="images.smallFirst" alt="")
        .col-6.pl-px-10.h-100
          ResponsiveImage.w-100.h-100(:image="images.smallSecond" alt="")
      .row.h-50
        .col-12.pt-px-20.h-100
          ResponsiveImage.w-100.h-100(:image="images.wide" alt="" :gravity="isNarrower('lg') ? 'auto' : null")

  .col-12.col-lg-4.px-0
    ResponsiveText.box-title.text-realblack.text-shadow-none(data-i18n="solresorStartPage.pitchTitle")
      span {{ $t('solresorStartPage.pitchTitle') }}
    i-wave.my-4.text-black.pb-2.ml-3.pl-1.scale-x-40(
      filled
      :font-controlled="false"
      width="100px"
    )
    p.mb-0(
      data-i18n="solresorStartPage.pitchText"
    ) {{ $t('solresorStartPage.pitchText') }}
</template>

<script>
export default defineNuxtComponent({
  data () {
    return {
      images: {
        smallFirst: 'solresor-startpage/small-1',
        smallSecond: 'solresor-startpage/small-2',
        wide: 'solresor-startpage/wide',
      },
    }
  },
})
</script>
