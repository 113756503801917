import { default as _91slug_93VNZrclYbAMMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue?macro=true";
import { default as _91slug_93ayMJTbw0OVMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue?macro=true";
import { default as _91name_930uFlXiXBQgMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue?macro=true";
import { default as _91id_93RrKO472A62Meta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/payment/[id].vue?macro=true";
import { default as _91id_93QKVXF06zsgMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/omdome/[id].vue?macro=true";
import { default as _91tab_936cCTlDCt6PMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue?macro=true";
import { default as _91slug_93pjdHbZsLOYMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/info/[slug].vue?macro=true";
import { default as _91tab_93bkUlp14v8wMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/hotel/[id]/[tab].vue?macro=true";
import { default as _91id_936k3zy0uVidMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/fn/[id].vue?macro=true";
import { default as _91tab_93LzjETH7CdoMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/destination/[id]/[tab].vue?macro=true";
import { default as _91slug_93IALoeVWokkMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/competition/[slug].vue?macro=true";
import { default as _91name_93oRPWReLg5AMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/campaign/[name].vue?macro=true";
import { default as _91ocr_93B3gbmGUmmKMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue?macro=true";
import { default as _91ocr_93Sx1frFaIKwMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue?macro=true";
import { default as _91id_930Hvt8DlgnkMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue?macro=true";
import { default as _91step_931tzCcETkbZMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue?macro=true";
import { default as _91jwt_93kzqDTW7qIPMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue?macro=true";
import { default as _91tab_93dU2gnonSaDMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue?macro=true";
import { default as activitiesreGjdkksAuMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/activities.vue?macro=true";
import { default as indexYHeXNgT1ibMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/app/index.vue?macro=true";
import { default as byebyeiefAMSkVQSIhMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/byebyeie.vue?macro=true";
import { default as gruppresorhdbQIPkc5FMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/campaign/gruppresor.vue?macro=true";
import { default as chartersearch0kmmSxRinPMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/chartersearch.vue?macro=true";
import { default as complaint7FmAsNX8GQMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/complaint.vue?macro=true";
import { default as indexcyCd5iTVfbMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/destination/index.vue?macro=true";
import { default as dinbokning_45aktuellXLoLFI0lYrMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue?macro=true";
import { default as faqFHiFummqy4Meta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/faq.vue?macro=true";
import { default as index2UH8kH0B5cMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/favourites/index.vue?macro=true";
import { default as _91id_935fs83YoDAqMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue?macro=true";
import { default as indexGScs7cxjnyMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/giftcard/index.vue?macro=true";
import { default as index9OOKXYuFQiMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/hotel/index.vue?macro=true";
import { default as _91name_93idfJpaKQWjMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/hotels/[name].vue?macro=true";
import { default as indexrfbVV4Z3QyMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/hotels/index.vue?macro=true";
import { default as indexBntsCc8ck9Meta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/index.vue?macro=true";
import { default as lastminuteYpgK41UZOPMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/lastminute.vue?macro=true";
import { default as dinbokningqNrLjzuehQMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue?macro=true";
import { default as newsletterqIhBy77DLuMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/newsletter.vue?macro=true";
import { default as offlineLgj4sixT2LMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/offline.vue?macro=true";
import { default as packagessearchCbJMAa19ALMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/packagessearch.vue?macro=true";
import { default as failedmW848tP2p1Meta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/payment/failed.vue?macro=true";
import { default as indexDizvCtkJBjMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/review/index.vue?macro=true";
import { default as searchEAcSps5vBpMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/search.vue?macro=true";
import { default as indexZQ89tkkpabMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/tourleader/index.vue?macro=true";
import { default as verification_45doneEeg4OijmBoMeta } from "/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/verification-done.vue?macro=true";
export default [
  {
    name: "tourleader-id",
    path: "/reseledare/:id()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "titinerary",
    path: "/dagprogram",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "shipinfo",
    path: "/fartyg",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "omdome",
    path: "/omdome",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "mytrip",
    path: "/dinresa",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/hotel/[id]/[tab].vue")
  },
  {
    name: "fn",
    path: "/fn",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "destination-id",
    path: "/destination/:id()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/destination/[id]/[tab].vue")
  },
  {
    name: "competition",
    path: "/tavling",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "campaign",
    path: "/campaign",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/campaign/[name].vue")
  },
  {
    name: "bookingfaq",
    path: "/boknings-faq",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "booking-finish",
    path: "/booking-finish",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "booking-confirmation",
    path: "/booking-confirmation",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "book-tripid",
    path: "/boka/:tripid()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "app-l",
    path: "/app/l",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "triptype-slug",
    path: "/:triptype()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "triptype-slug-tab",
    path: "/:triptype()/:slug()/:tab()",
    meta: _91tab_93dU2gnonSaDMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "activities",
    path: "/utflykter-och-aktiviteter",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/activities.vue")
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/app/index.vue")
  },
  {
    name: "app-l-jwt",
    path: "/app/l/:jwt()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "book-tripid-step",
    path: "/boka/:tripid()/:step()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "booking-confirmation-id",
    path: "/booking-confirmation/:id()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "booking-finish-ocr",
    path: "/booking-finish/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "bookingfaq-ocr",
    path: "/boknings-faq/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "byebyeie",
    path: "/byebyeie",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/byebyeie.vue")
  },
  {
    name: "campaign-name",
    path: "/campaign/:name()",
    meta: _91name_93oRPWReLg5AMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/campaign/[name].vue")
  },
  {
    name: "campaign-gruppresor",
    path: "/campaign/gruppresor",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/campaign/gruppresor.vue")
  },
  {
    name: "chartersearch",
    path: "/sok-charter",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/chartersearch.vue")
  },
  {
    name: "competition-slug",
    path: "/tavling/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "complaint",
    path: "/reklamation",
    meta: complaint7FmAsNX8GQMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/complaint.vue")
  },
  {
    name: "destination-id-tab",
    path: "/destination/:id()/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/destination/[id]/[tab].vue")
  },
  {
    name: "destination",
    path: "/destination",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/destination/index.vue")
  },
  {
    name: "dinbokning-aktuell",
    path: "/dinbokning-aktuell",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/faq.vue")
  },
  {
    name: "favourites",
    path: "/favourites",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/favourites/index.vue")
  },
  {
    name: "fn-id",
    path: "/fn/:id()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "giftcard-id",
    path: "/presentkort/:id()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue")
  },
  {
    name: "giftcard",
    path: "/presentkort",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/giftcard/index.vue")
  },
  {
    name: "hotel-id-tab",
    path: "/hotel/:id()/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/hotel/[id]/[tab].vue")
  },
  {
    name: "hotel",
    path: "/hotel",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/hotel/index.vue")
  },
  {
    name: "hotels-name",
    path: "/hotell/:name()",
    meta: _91name_93idfJpaKQWjMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/hotels/[name].vue")
  },
  {
    name: "hotels",
    path: "/hotell",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/hotels/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/index.vue")
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    meta: _91slug_93pjdHbZsLOYMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "lastminute",
    path: "/sista-minuten-erbjudanden",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/lastminute.vue")
  },
  {
    name: "mytrip-tab",
    path: "/dinresa/:tab()",
    meta: _91tab_936cCTlDCt6PMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "mytrip-dinbokning",
    path: "/mytrip/dinbokning",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue")
  },
  {
    name: "newsletter",
    path: "/nyhetsbrev",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/newsletter.vue")
  },
  {
    name: "offline",
    path: "/offline",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/offline.vue")
  },
  {
    name: "omdome-id",
    path: "/omdome/:id()",
    meta: _91id_93QKVXF06zsgMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "packagessearch",
    path: "/sok-packages",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/applications/solresor-web/pages/packagessearch.vue")
  },
  {
    name: "payment-id",
    path: "/payment/:id()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "payment-failed",
    path: "/payment/failed",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/payment/failed.vue")
  },
  {
    name: "review",
    path: "/utvardera-resa",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/review/index.vue")
  },
  {
    name: "search",
    path: "/sok",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/search.vue")
  },
  {
    name: "shipinfo-name",
    path: "/fartyg/:name()",
    meta: _91name_930uFlXiXBQgMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "titinerary-slug",
    path: "/dagprogram/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "tourleader-id-slug",
    path: "/reseledare/:id()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "tourleader",
    path: "/reseledare",
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/tourleader/index.vue")
  },
  {
    name: "verification-done",
    path: "/verification-done",
    meta: verification_45doneEeg4OijmBoMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-25-1214/rtg-monorepo/src/layers/web/pages/verification-done.vue")
  }
]