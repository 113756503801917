// Translated
// Migrated
<template lang="pug">
component.nav-item.favourites-item.solresor-favourites(
  :is="parentElement.type"
)
  nuxt-link.nav-link.d-flex.align-items-center.h-100.favourites-item-link(
    to="/favourites",
    :class="{'favourites-item-link--refresh': refresh}",
    :style="{'animation-duration': `${duration}ms`}"
  )
    img.svg-icon(
      alt="favourite",
      :src="iconSource"
      width="16"
      height="16"
    )
    span(v-if="(n > 0)") {{ n }}
</template>

<script>
import events from '@layers/web/constants/events.js'
import localStorageKeys from '@layers/web/constants/local-storage-keys'

export default defineNuxtComponent({
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      n: 0,
      refresh: false,
      timeout: null,
      duration: 400,
    }
  },

  computed: {
    parentElement () {
      if (this.isMobile) {
        return {
          type: 'div',
        }
      } else {
        return {
          type: 'li',
        }
      }
    },

    iconSource () {
      return `/icons/heart-${(this.n > 0) ? 'pink' : 'grey'}.svg`
    },
  },

  mounted () {
    this.update()

    this.$event.$on(events.setFavourites, () => {
      this.update()
    })
  },

  beforeUnmount () {
    clearTimeout(this.timeout)
    this.$event.$off(events.setFavourites, () => null)
  },

  methods: {
    update () {
      let n = 0
      try {
        const favourites = JSON.parse(window.localStorage.getItem(localStorageKeys.zz.favourites))
        n = (favourites || []).length
      } catch {
        // Do nothing
      }

      this.n = n
      if (n > 0) {
        this.refresh = true
        this.timeout = window.setTimeout(() => (this.refresh = false), this.duration)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.favourites-item {
  padding: 0 24px;

  @media (max-width: $mxl) {
    padding: 0 12px;
  }
  @media (max-width: $xl) {
    padding: 0 8px;
  }
}
</style>
