<template lang="pug">
#charterWindowAirport.charter-search__window.left-0.w-100(
  v-if="active"
  ref="charterWindowAirport"
  key="charterWindowAirport"
)
  .charter-search__window-content.overflow-auto.bg-lg-white(
    v-click-outside="{ fn: active ? () => $emit('close') : () => null, ignore: '.charter-filter__introduction,.package-search,.charter-search__btn' }"
    :class="$isSol ? 'bg-light-turquoise' : 'bg-white-blue'"
  )
    PackageSearchBarWindowHeader.bg-light-turquoise.mx-lg-0.mb-lg-3(
      :select-error="selectAirportFirst ? $t('charterSelectAirportFirst') : null"
      :class="$isSol ? 'pl-4 pr-2 mx-n3 mx-sm-n4 mt-md-n4 mt-lg-0 py-3' : 'pl-4 pl-lg-0 mx-n3 mb-0 bg-white mt-md-n3 mt-lg-0'"
      @close="$emit('close')"
    ) {{ $t('charterSelectDeparture') }}

    .row.mx-n4.mx-lg-0(
      :style="{ rowGap: '15px'}"
      data-test-airports
    )
      .col-12.bg-white.py-3.py-lg-0.px-lg-0(v-if="featuredAirports.length" :class="!onlyFeaturedAirports ? singleColumnNormalAirports ? 'col-lg-3' : 'col-lg-3' : ''")
        ul.w-100.h-lg-100.border-lg-right
          li.pr-lg-4(v-for="airport in featuredAirports" :key="airport.airport")
            button.h6.py-2.px-3.px-md-4.mb-0.bg-transparent.hover-bg-light-turquoise.ls-1.text-nowrap.text-left.rounded-4.w-100(
              type="button",
              data-test-airport,
              :class="{ [activeClass]: airport.airport === airportIsSelected }"
              @click="selectAirport(airport)"
            ) {{ airport.airport_name }}

      .px-0.mx-0.overflow-hidden(v-if="normalAirports.length" :class="singleColumnNormalAirports ? 'col-lg-3' : 'col-lg-9'")
        ul.mb-0.mr-lg-n4.column-gap-none(:class="singleColumnNormalAirports ? 'two-columns' : 'four-columns'")
          li.border-right.mr-px-n1.px-3.px-lg-0.d-flex.px-lg-4(v-for="airport in normalAirports" :key="airport.airport")
            button.h6.font-weight-medium.w-100.font-weight-lg-normal.py-2.px-3.px-md-4.mb-0.bg-transparent.hover-bg-light-turquoise.ls-1.text-nowrap.text-center.text-lg-left.rounded-4.position-relative.flex-fill(
              type="button",
              data-test-airport,
              :class="{ [activeClass]: airport.airport === airportIsSelected }"
              @click="selectAirport(airport)"
            )
              .d-inline-block.left-lg-15.position-relative {{ airport.airport_name }}
</template>

<script>
import { mapActions, mapState } from 'pinia'
import expirations from '@packages/cache/expirations'

import { ALL_AIRPORTS_CODE } from '@layers/web/constants/charter-packages.ts'

export default defineNuxtComponent({
  setup () {
    const setCookie = (name, val, opts) => {
      if (import.meta.browser) {
        const cookie = useCookie(name, { ...opts })
        if (val) {
          cookie.value = val
        }
      }
    }

    return {
      setCookie,
    }
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    selectedAirport: {
      type: Object,
      default: () => {},
    },

    selectAirportFirst: {
      type: Boolean,
      default: false,
    },

    prefilledSelectedAirport: {
      type: Object,
      default: () => {},
    },

    prefill: {
      type: Object,
      default: null,
    },

    airportOptions: {
      type: Array,
      default: null,
    },

    chokeInitiation: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close', 'select'],

  data () {
    return {
      widthClass: 'xl',
    }
  },

  computed: {
    ...mapState(useCharterPackagesStore, {
      destinations: 'destinations',
      selectedAirportStore: 'selectedAirport',
      categoriesForAirport: 'categoriesForAirport',
    }),

    ...mapState(useRootStore, {
      airports: 'charterAirports',
    }),

    ...mapState(useSearchWidgetsStore, {
      stagedDestinationsL1: 'stagedDestinationsL1',
    }),

    activeClass () {
      if (this.$isSol) {
        return 'bg-vivid-turquoise'
      }

      return 'bg-medium-blue text-white'
    },

    filteredAirports () {
      const optionsProvided = (this.airportOptions || []).length > 0
      const list = optionsProvided ? this.airportOptions : (this.airports || [])
      return list.filter(a => a?.airport !== 'ZZZ')
    },

    normalAirports () {
      return this.filteredAirports.filter(a => !a?.featured_airport)
    },

    featuredAirports () {
      return this.filteredAirports.filter(a => !!a?.featured_airport)
    },

    onlyFeaturedAirports () {
      return this.featuredAirports.length > 0 && this.normalAirports.length === 0
    },

    singleColumnNormalAirports () {
      return this.normalAirports.length < 5
    },

    airportIsSelected () {
      return this.selectedAirport?.airport
    },
  },

  watch: {
    prefilledSelectedAirport (v) {
      if (!v || v?.airport === this.selectedAirport?.airport) {
        return
      }
      this.selectAirport(v, false)
    },

    selectedAirportStore (v) {
      if (!v || v?.airport === this.selectedAirport?.airport) {
        return
      }
      this.selectAirport(v, false)
    },
  },

  mounted () {
    if (!this.chokeInitiation) {
      let preSelectedAirport
      const localAirport = this.setCookie('charterAirport')

      if (this.prefilledSelectedAirport) {
        preSelectedAirport = this.prefilledSelectedAirport
      } else if (this.prefill && this.prefill?.selectedAirport) {
        preSelectedAirport = this.prefill?.selectedAirport || this.selectedAirport
      } else if (localAirport) {
        preSelectedAirport = this.filteredAirports.find(x => x?.airport === localAirport)
      } else if (this.selectedAirportStore && this.selectedAirportStore?.airport !== ALL_AIRPORTS_CODE) {
        preSelectedAirport = this.selectedAirportStore
      }

      if (preSelectedAirport) {
        this.selectAirport(preSelectedAirport, false)
      }
    }
  },

  methods: {
    ...mapActions(useSearchWidgetsStore, {
      setStagedAirport: 'SET_STAGED_AIRPORT',
    }),

    ...mapActions(useCharterPackagesStore, {
      setAirport: 'SET_SELECTED_AIRPORT',
    }),

    selectAirport (airport, fullUpdate = true) {
      this.$emit('select', [airport, fullUpdate])

      if (airport?.airport !== this.selectedAirportStore?.airport) {
        this.setStagedAirport(airport)
        this.setAirport(airport)
      }

      if (airport?.airport === ALL_AIRPORTS_CODE) {
        return
      }
      try {
        window.localStorage.setItem('charterAirport', JSON.stringify(airport))
      } catch {
        // Storage Exceeded
      }
      this.setCookie(
        'charterAirport',
        airport,
        {
          maxAge: expirations.longDuration,
          sameSite: 'lax',
          path: '/',
        }
      )
    },
  },
})
</script>

<style lang="scss">
#charterWindowAirport {
  &.sm .row {
    & > div:first-of-type,
    & > div:last-of-type {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &.md .row {
    & > div:first-of-type {
      flex: 1 0 33.33333;
      max-width: 33.33333%;
    }
    & > div:last-of-type {
      flex: 1 0 66.66666%;
      max-width: 66.66666%;
    }
  }

  &.lg .row,
  &.xl .row {
    & > div:first-of-type {
      flex: 1 0 25%;
      max-width: 25%;
    }
    & > div:last-of-type {
      flex: 1 0 75%;
      max-width: 75%;
    }
  }
}
</style>
