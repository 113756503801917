//TODO: needs flexed wrapping: .d-flex.flex-column.align-items-center
// Translated
// Migrated
<template lang="pug">
span.campaign-tag(
  :class="tagClasses"
  @click="onClick"
)
  slot
    span.text-nowrap.d-flex.align-items-center
      template(v-if="showIcon && tag?.icon")
        i-solresor-icon.svg-icon.mr-1(v-if="tag.icon === 'solresor'" alt="Solresor" filled :font-controlled="false")
        CampaignIcon.mr-1(v-else, :icon="tag.icon" :size="iconSize" :color="iconColor" alt="campaign-icon")
      span(:data-i18n="tag.i18n") {{ tag ? tag.title : '' }}
</template>

<script>
import { mapState } from 'pinia'

/**
 * When expanding this, make sure to expand assets/scss/variables $tag-colors
 */
const styles = {
  default: {
    bg: 'blue',
    text: 'white',
    icon: 'white',
  },
  blue: {
    bg: 'blue',
    text: 'white',
    icon: 'white',
  },
  orange: {
    bg: 'orange',
    text: 'white',
    icon: 'white',
  },
  green: {
    bg: 'green',
    text: 'white',
    icon: 'white',
  },
  red: {
    bg: 'red',
    text: 'white',
    icon: 'white',
  },
  yellow: {
    bg: 'yellow',
    text: 'white',
    icon: 'white',
  },
  primary: {
    bg: 'primary',
    text: 'black',
    icon: 'black',
  },
  'light-green': {
    bg: 'light-green',
    text: 'white',
    icon: 'white',
  },
  brown: {
    bg: 'brown',
    text: 'white',
    icon: 'white',
  },
  'light-blue': {
    bg: 'light-blue',
    text: 'white',
    icon: 'white',
  },
  coral: {
    bg: 'coral',
    text: 'white',
    icon: 'white',
  },
  pool: {
    bg: 'pool',
    text: 'white',
    icon: 'white',
  },
  'royal-purple': {
    bg: 'royal-purple',
    text: 'white',
    icon: 'white',
  },
  burgundy: {
    bg: 'burgundy',
    text: 'white',
    icon: 'white',
  },
  sunny: {
    bg: 'sunny',
    text: 'black',
    icon: 'black',
  },
  'sky-blue': {
    bg: 'sky-blue',
    text: 'black',
    icon: 'black',
  },
  beach: {
    bg: 'beach',
    text: 'black',
    icon: 'black',
  },
  pink: {
    bg: 'pink',
    text: 'white',
    icon: 'white',
  },
  'coconut-brown': {
    bg: 'coconut-brown',
    text: 'white',
    icon: 'white',
  },
  indigo: {
    bg: 'indigo',
    text: 'white',
    icon: 'white',
  },
  'dark-purple': {
    bg: 'dark-purple',
    text: 'white',
    icon: 'white',
  },
  plum: {
    bg: 'plum',
    text: 'white',
    icon: 'white',
  },
  black: {
    bg: 'black',
    text: 'white',
    icon: 'white',
  },
  turquoise: {
    bg: 'turquoise',
    text: 'white',
    icon: 'white',
  },
  'dark-pink': {
    bg: 'dark-pink',
    text: 'white',
    icon: 'white',
  },
  'gradient': {
    bg: 'gradient',
    text: 'white',
    icon: 'white',
  },
}

const sizes = {
  sm: { tag: '--small', icon: 22 },
  md: { tag: '--medium', icon: 24 },
  lg: { tag: '--large', icon: 30 },
}

export default defineNuxtComponent({
  name: 'CampaignTag',

  props: {
    tag: {
      type: Object,
      default: null,
    },

    tagStyle: {
      type: String,
      default: 'default',
      validate: value => Object.keys(styles).includes(value),
    },

    size: {
      type: String,
      default: 'md',
      validate: value => Object.keys(sizes).includes(value),
    },

    showIcon: {
      type: Boolean,
      default: true,
    },

    pill: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(useWhitelabelStore, {
      whitelabel: 'whitelabel',
    }),

    isClickable () {
      if (this.whitelabel) {
        return false
      }
      return this.tag?.url_name
    },

    currentStyle () {
      return styles?.[this.tag?.style || this.tagStyle] || styles.default
    },

    currentSize () {
      return sizes?.[this.size] || sizes.sm
    },

    tagClasses () {
      const classes = [
        `tag__bg--${this.currentStyle?.bg}`,
        `tag__color--${this.currentStyle?.text}`,
        `tag__icon--${this.currentStyle?.icon}`,
        this.currentSize?.tag,
      ]

      if (this.pill) {
        classes.push('pill')
      }

      if (this.showIcon) {
        classes.push('text-uppercase font-weight-bold')

        if (this.tag?.icon) {
          classes.push('--with-icon')

          if (this.tag?.icon === 'solresor') {
            classes.push('--solresor-icon')
          }
        }
      } else {
        classes.push('rounded-4 font-weight-medium')
      }

      if (this.isClickable) {
        classes.push('is-clickable')
      }

      return classes.join(' ')
    },

    iconSize () {
      return sizes[this.size]?.icon
    },

    iconColor () {
      return this.currentStyle?.icon
    },
  },

  methods: {
    onClick () {
      if (this.isClickable) {
        this.$router.push({
          name: 'charterpackagescampaign-name',
          params: {
            name: this.tag?.url_name,
          },
        }).catch(() => null)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.campaign-tag {
  &.is-clickable {
    cursor: pointer;
  }
}

.pill {
  padding: 0.3rem 1rem;
  border-radius: 3rem;
  font-size: 0.9rem;
  font-weight: bold;
}

/*! purgecss start ignore */
.tag {
  &__bg {
    @each $name, $color in $tag-colors {
      &--#{"" + $name} {
        background: $color
      }
    }
  }
  &__color {
    &--white {
      color: white;
    }
    &--black {
      color: black;
    }
  }
  &__icon {
    &--white {
      &.--solresor-icon {
        img {
          filter: invert(1);
        }
      }
    }
  }
}

.--small {
  font-size: 0.75rem;
  padding: 0.35rem 0.75rem;

  &.--with-icon {
    padding: 0.35rem 0.75rem 0.35rem 0.5rem;
  }
}

.--medium {
  padding: 0.2rem 1.5rem;

  &.--with-icon {
    padding: 0.2rem 1.5rem 0.2rem 0.8rem;
  }
}

.--large {
  font-size: 1.1rem;
  padding: 0.35rem 0.75rem;

  &.--with-icon {
    padding: 0.35rem 0.75rem 0.35rem 0.5rem;
  }
}
/*! purgecss end ignore */
</style>
