// Migrated
<template lang="pug">
#charterButtonDate.charter-search__btn(
  ref="charterButtonDate",
  :aria-expanded="isActive"
  role="button"
  aria-haspopup="true"
  aria-labelledby="charterButtonLabelDate"
  @click="$emit('click')"
)
  .charter-search__btn-title#charterButtonLabelDate.d-flex.w-100
    fa.mr-2(v-if="!$isSol" :icon="icons.faCalendarAlt")
    span {{ $t('charterSearchDate') }}
    fa.charter-search__btn-icon.ml-auto.transition-transform(
      v-if="$isSol"
      icon="chevron-down"
      :style="{ transform: `rotateX(${isActive ? '180deg' : '0'})` }"
    )
  .charter-search__btn-text
    span(v-if="lastminute") {{ $t('lastMinuteTitle') }}
    span(v-else-if="!(selectedDateRangeStartDate || selectedDateRangeEndDate)") {{ $t('charterSearchSelect') }}
    span(v-else) {{ localeDate(selectedDateRangeStartDate) }}
      //- TODO handle custom duration
      small.d-block(v-if="!selectedDateRangeEndDate && selectedDateRangeDuration?.value === 'custom'") {{ customRange }}
      small.d-block(v-else-if="selectedDateRangeEndDate") {{ $t('charterSearchDateUntil', { n: selectedDateRangeEndDate }) }}
      small.d-block(v-else-if="selectedDateRangeDuration") +{{ $t('charter.duration.week', Math.round(selectedDateRangeDuration / 7)) }}
    fa.charter-search__btn-icon(
      v-if="!$isSol"
      :icon="isActive ? 'chevron-up' : 'chevron-down'"
    )

  slot(name="error-message")
  .charter-search__btn-title.text-error.blink.blink-title(v-if="selectedDateRangeStartDate && !selectedDateRangeEndDate && !selectedDateRangeDuration") {{ $t('charterLegendSelectFlightDates') }}
</template>

<script>
import { faCalendarAlt } from '@fortawesome/pro-duotone-svg-icons'
import { mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { localeDate } = useDate()

    return {
      localeDate,
    }
  },

  name: 'PackageSearchDateRangeButton',

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],

  data () {
    return {
      icons: {
        faCalendarAlt,
      },
    }
  },

  computed: {
    ...mapState(useCharterPackagesStore, {
      selectedDateRangeStartDate: 'selectedDateRangeStartDate',
      selectedDateRangeEndDate: 'selectedDateRangeEndDate',
      selectedDateRangeDuration: 'selectedDateRangeDuration',
      lastminute: 'lastminute',
    }),

    customRange () {
      if (this.selectedDateRangeDuration?.value === 'custom' && this.selectedDateRangeDuration.custom?.length === 2) {
        const [endDateDurationStart, endDateDurationEnd] = this.selectedDateRangeDuration.custom
        return `+${endDateDurationStart} ${this.$t('to')} ${this.$t('charterSearchPeriodDay', endDateDurationEnd)}`
      }

      return null
    },
  },
})
</script>

<style lang="scss" scoped>

</style>
